import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './assets/css/style.css';
import './assets/fonts/fonts.css'
import About from './components/Pages/About';

function App() {
  return (
    <>
      <About/>
    </>
  );
}

export default App;
