import React, { useState } from 'react'
import { Container, Dropdown, DropdownButton, Form, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SimpleReactValidator from 'simple-react-validator';
import axios from "axios";

const Header = () => {
  const [show, setShow] = useState(false);
  const [state, setState] = useState(false);
  const [alertdb, setAlertdb] = useState(false);
  const [email,setEmail] = useState("");
  const handleClose = () => setShow(false);
   const handleCloseForgotPassword = () => {
    setForgotPassword(false)
  };
  const handlenotificationpop = () => setNotificationpop(false);
  const handlenoemail = () => setNoemail(false);
  const handleShow = () => setShow(true);
  const [notificationpop, setNotificationpop] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [noemail, setNoemail] = useState(false);
  const [academy, setAcademy] = useState("");
  
  const academyDropDownClicked = (event) => {
     setAcademy(event)
  }
  const location = useLocation();
 
  const [validator2] = React.useState(new SimpleReactValidator());
  const [renderAfterValidation, setRenderAfterValidation] = useState(0);
  const forgotmail = async (e)=>{
    console.log(academy);
    e.preventDefault();
    if (validator2.allValid()) {
        setAlertdb(false)
        let acdemy_var = academy==='2023 Advanced Academy' ? 378 : 367; 
       
        axios.defaults.baseURL = "https://webinar.docintel.app/flow/EventRegister/ForgotLink";

        let body= {
           email:email,
           eventId:acdemy_var,
           companyId:2147499629,
         
        }
        await axios
          .post(`signup`, body)
          .then((res) => {
            console.log(res.data.message )
            if (res.data.message === "Mail sent to your email") {
             
              setNotificationpop(true)
              setForgotPassword(false)
              setEmail("")
              validator2.hideMessages();
                console.log(res.data.data.user_id)
                //window.location = "https://vwdacademy.docintel.app/redirect-to-upload/378/"+res.data.data.user_id
              // setShow(false);
            } else {
              setForgotPassword(false)
              setNoemail(true)
              
              console.log("am in")
              setEmail("")
              validator2.hideMessages();
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }else{
        setAlertdb(false)
        validator2.showMessages();
        setRenderAfterValidation(renderAfterValidation + 1);
    }


  }
  let path_image = process.env.REACT_APP_Miami_website_image;
     return (<>
     {location.pathname==="/MU/2022/register" ? null:
      <header>
      {['lg'] .map((expand) => (
      <Navbar  key={expand} bg="light" expand={expand}>
      
      <Container>
        <div className="d-flex justify-content-between navbar-outer align-items-center">
            <Navbar.Brand><Link to={"/MU/2022/about"}><img src="/images/vwd-academy-logo.svg" alt="" /></Link></Navbar.Brand>
            <Navbar.Toggle onClick={()=>{setState(true)}} aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                 <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`}  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end">
                <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Link to={"/MU/2022/about"}><img src="/images/vwd-academy-logo.svg" alt="VWD Academy" /></Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
          <Offcanvas.Body>
           <Nav className="justify-content-between d-flex nav_list align-items-center">
                <Link className={location.pathname === "/MU/2022/about" ? "active":""} to={"/MU/2022/about"}>About</Link>
                <Link className={location.pathname === "/MU/2022/course" ? "active":""} to={"/MU/2022/course"}>Course</Link>
                <Link className={location.pathname === "/MU/2022/faculty" ? "active":""} to={"/MU/2022/faculty"}>Faculty</Link>
                <Link className={location.pathname === "/MU/2022/testimonial" ? "active":""} to={"/MU/2022/testimonial"}>Testimonial</Link>
                <a className={location.pathname === "https://vwdtest.com/" ? "site_link active":"site_link"} href="https://vwdtest.com/" target="_blank">VWDtest.com</a>
                <a id="login-btn" className="login-btn" href="#" onClick = {()=>{
                    setForgotPassword(true)
                    setShow(false)
                    }}>Login</a>
                
                {/* <Link className={location.pathname === "/MU/2023/register" ? "active":""} id="login-btn" to={"/MU/2023/register"}>Login</Link> */}
                {/* <Link className="login-popup" id="login-btn"  onClick={handleShow}>Login</Link> */}
                {/* <Button variant="primary" onClick={handleShow} id="login-btn">Login</Button> */}
              </Nav> 
            
              </Offcanvas.Body>
            </Navbar.Offcanvas> 
        </div>
        
      </Container>

    </Navbar>
           ))}
    </header>
}

      <Modal show={show} onHide={handleClose} className="login_modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="vwd-logo"><img src="/images/login-logo.svg" alt="Logo"/></div>
            <Form>
                <Form.Group className='email-field'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Type Your Email" />
                </Form.Group>
                <Form.Group className='password-field'>
                    <Form.Label>Password</Form.Label>
                     <Form.Control type="password" placeholder="Type Your Password" />
                </Form.Group>
                <Form.Group className="mb-3 forget_password" controlId="formBasicCheckbox">
                  <Form.Label><a href="#" onClick = {()=>{
                    setForgotPassword(true)
                    setShow(false)
                    }}>Forget password?</a></Form.Label>
                </Form.Group>
                <Button variant="primary" type="submit">
                   Login
                </Button>
                
            </Form>
        </Modal.Body>
        
      </Modal>

   
      <Modal show={forgotPassword} onHide={handleCloseForgotPassword} className="login_modal forgot" centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="vwd-logo"><img src="/images/login-logo.svg" alt="Logo"/></div>
          <h6>Enter the email address you used to register and we will send you an email wtih a link to directly access your account.</h6>
            <Form onSubmit={(e)=>forgotmail(e)}>
                  <Form.Group className="form-group select-event">
                  <DropdownButton
                    className={"dropdown-basic-button split-button-dropup"}
                   title={
                      academy != "" && academy != "undefined"
                        ? academy
                        : "Select Academy"
                    }
                    onSelect={(event) => academyDropDownClicked(event)}
                    
                    >
                     
                    <div className="dropdown-menu-list">
                    <Dropdown.Item eventKey="2023 Advanced Academy">
                        2023 Advanced Academy
                      </Dropdown.Item>
                      <Dropdown.Item eventKey=" 2022 Advanced Academy">
                       2022 Advanced Academy
                      </Dropdown.Item>
                     
                    </div>
                  </DropdownButton>
                </Form.Group>
                <Form.Group className='email-field'>
                    <Form.Control type="email" placeholder="Type Your Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                    {validator2.message("email", email, "required|email")}
                </Form.Group>

                <Button variant="primary" type="submit">
                   Send
                </Button>
                
            </Form>
        </Modal.Body>
        
      </Modal>
      
      <Modal show={notificationpop} onHide={handlenotificationpop} className="login_modal forgot" centered>
        <Modal.Header closeButton>
          <Modal.Title className='orange_ad'>You've got mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Please click the login button in your mail</h6>
          <Form className="close_btn_parent" >
            <Button className="close_btn" type="button" onClick={(e)=>setNotificationpop(false)}>Ok</Button>
          </Form>
           
        </Modal.Body>
        
      </Modal>

      <Modal show={noemail} onHide={handlenoemail} className="login_modal forgot sorry" centered>
        <Modal.Header closeButton>
          <Modal.Title className='orange_ad'>Sorry!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>This email is not in use, can you please check if you were registered with another email?</h6>
          <Form className="close_btn_parent" >
            <Button className="close_btn" type="button" onClick={(e)=>handlenoemail(false)}>
                   Ok
                </Button>
                <Button className="close_btn bordered" type="button" onClick={(e)=>handlenoemail(false)}>
                   Contact Us
                </Button>
          </Form>
           
        </Modal.Body>
        
      </Modal>
   

     </>);
}
export default Header;