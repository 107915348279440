import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
const Faculty = () => {
const [readMoreClicked,setReadMoreClicked] = useState(false);
const [readMoreClicked1,setReadMoreClicked1] = useState(false);
const [readMoreClicked2,setReadMoreClicked2] = useState(false);
const [readMoreClicked3,setReadMoreClicked3] = useState(false);
const [readMoreClicked4,setReadMoreClicked4] = useState(false);
const [readMoreClicked5,setReadMoreClicked5] = useState(false);
const [readMoreClicked6,setReadMoreClicked6] = useState(false);
const [readMoreClicked7,setReadMoreClicked7] = useState(false);
const [readMoreClicked8,setReadMoreClicked8] = useState(false);
const [readMoreClicked9,setReadMoreClicked9] = useState(false);
let path_image = process.env.REACT_APP_Miami_website_image;
    return(
        <>
       <div className="faculty">
            <Container>
                <Row>
                    <div className="faculty-block">
                        <h2>Meet the VWD Academy FACULTY</h2>
                        <p><span>Our Faculty, invited speakers and staff make your VWD Academy a success! Our experts provide attendees with stimulating and interactive lectures and workshops led with passion and creativity.</span></p>
                        <p>Scroll down to get to know them better.</p>
                    </div>
                    <div className="faculty-listing">
                        <div className="faculty-list-box">
                            <div className="faculty-type">
                                Faculty member
                            </div>
                            <div className="faculty-name">
                                <h5>Fernando F. Corrales-Medina, MD</h5>
                            </div>
                            <p>Dr. Fernando F Corrales-Medina, VWD Academy Director, is currently an Associate Professor of Clinical Pediatrics at the University of Miami -Miller School of Medicine, Division of Pediatric Hematology-Oncology, where he also serves as the medical co-director for the pediatric hemophilia program at the University's Comprehensive Hemophilia Treatment Center and as the director for the pediatric hematology-oncology fellowship program. 
                       {readMoreClicked == true ?<span>
                                <p>He attended medical school in Peru. He then completed his pediatrics residency at the University of Miami - Miller School of Medicine in 2011 and his pediatric hematology-oncology fellowship training at The University of Texas - MD Anderson Cancer Center, from which he was graduated in 2014. </p> 
                                <p>He is currently the national and on-site Principal Investigator for several, pediatric and adult, hemophilia, and thrombosis clinical trials. Since joining the University of Miami, Dr. Corrales-Medina has received several research and teaching awards including the Micah Batchelor Scholar Award in 2017 and the 2018 American Thrombosis and Hemostasis Network (ATHN) - DREAM Award.  
                                </p>   
                                Dr. Corrales-Medina's primary clinical interests are hemostasis and thrombosis disorders with an emphasis on novel therapies and imaging diagnostic tools. His research has been published in several peer-reviewed journals, including the Haemophilia, Journal of Thrombosis and Haemostasis, Blood Reviews, Thrombosis Research, Journal of Pediatric Hematology-Oncology, Pediatrics in Review, among others.
                            </span>:null}
                        {readMoreClicked == true ?  <Button onClick = {()=> {setReadMoreClicked(false)}} variant="primary"> &lt; Read Less </Button>: <Button onClick = {()=> {setReadMoreClicked(true)}} variant="primary">Read More &gt;</Button>}  </p>
                            <div className="faculty-memder-image">
                                <img src="/images/fernando.jpg"  alt="" />
                            </div>
                        </div>
                        <div className="faculty-list-box">
                            <div className="faculty-type">
                                Faculty member
                            </div>
                            <div className="faculty-name">
                                <h5>Joanna Davis, MD</h5>
                            </div>
                            <p>Dr. Joanna Davis is an Associate Professor of Clinical Pediatrics at the University of Miami Leonard Miller School of Medicine in Miami, Florida. She is the medical co- director of the University of Miami Comprehensive Pediatric Hemophilia Treatment Center, which she started in 1987. The combined Adult and Pediatric Center is one of the largest in the Southeast Region of the United States, with over 400 patients with a variety of bleeding and clotting disorders. {readMoreClicked1 == true ? <span>
                                <p>Dr. Davis received her undergraduate degree from Barnard College of Columbia University.  She attended the Albert Einstein College of Medicine in New York.  Dr. Davis did her internship and first year residency year in Pediatrics at Montefiore Hospital Medical Center in the Bronx, NY, and her final year of residency at Jackson Memorial Hospital in Miami.   She completed her Fellowship in Pediatric Hematology/Oncology at Jackson Memorial Hospital as well and is board-certified in both Pediatrics and Pediatric Hematology/Oncology.</p> 
                                Dr Davis has been, and remains, involved in numerous clinical trials studying new therapies and interventions for hemophilia and von Willebrand's Disease. She is a strong advocate for patient and provider education, has published many books for patients and their families, and has developed an introductory Hemophilia lecture series for health care providers which has been presented to close to 4000 physicians and nurses around the country.  Dr Davis is a member of ASH, ISTH, ATHN and HTRS.  She serves on numerous advisory boards and has been involved in organizations and efforts focusing on women with bleeding disorders. She is active in the local community, meeting with patients and advocacy groups and presenting the latest research and advice to people living with coagulation disorders. 
                            </span>:null}
                        {readMoreClicked1 == true ?  <Button onClick = {()=> {setReadMoreClicked1(false)}} variant="primary"> &lt; Read Less </Button>: <Button onClick = {()=> {setReadMoreClicked1(true)}} variant="primary">Read More &gt;</Button>}  </p>
                            <div className="faculty-memder-image">
                                <img src="/images/joanna.jpg"  alt="" />
                            </div>
                        </div>
                        <div className="faculty-list-box">
                            <div className="faculty-type">
                                Faculty member
                            </div>
                            <div className="faculty-name">
                                <h5>Judy Simms-Cendan, MD</h5>
                            </div>
                            <p>Dr. Judy Simms-Cendan is the Division Director of Pediatric Adolescent Gynecologist at the University of Miami Miller School of Medicine (UMMSOM) in the department of Obstetrics, Gynecology and Reproductive Sciences. She has a courtesy appointment in the department of Pediatrics.</p>
                            <p>Dr. Simms-Cendan graduated from the University of Miami with her Bachelor of Science,
                                {readMoreClicked2 == true ? <span>and from the University of Florida College of Medicine with her MD degree. She completed her residency training in OBGYN at the University of Florida in 1995, then served on the faculty at UF until joining as founding faculty at the University of Central Florida College of Medicine in 2010. She opened the first practice in Florida fully dedicated to Pediatric Adolescent Gynecology patients in Orlando in 2010. In July 2021 she joined the UMMSOM to develop dedicated Pediatric Adolescent Gynecology practices. Working with the Pediatric Hematology faculty and the Hemophilia Treatment Center at UM, she developed the Cycle Clinic, a biweekly multidisciplinary clinic dedicated to the care of adolescents with heavy menstrual bleeding due to bleeding disorders.<br /><br/>
                                Dr. Simms-Cendan is President-elect of the North American Society of Pediatric Adolescent Gynecology and serves on the executive board of the International Federation of Pediatric Adolescent Gynecology. She has written national guidelines for care of adolescents with heavy menses and bleeding disorders (ACOG CO 785, 2019), and international advocacy statements to call attention to the need for improved care for adolescent girls with menstrual disorders. As a Clinician Educator, Dr. Simms-Cendan enjoys teaching and learning from her patients and their families, medical students, residents, and colleagues.
                            </span>:null}
                        {readMoreClicked2 == true ?  <Button onClick = {()=> {setReadMoreClicked2(false)}} variant="primary"> &lt; Read Less </Button>: <Button onClick = {()=> {setReadMoreClicked2(true)}} variant="primary">Read More &gt;</Button>} </p>
                            <div className="faculty-memder-image">
                                <img src="/images/judy.jpg"  alt="" />
                            </div>
                        </div>
                        <div className="faculty-list-box">
                            <div className="faculty-type">
                                Special GuesT <span>|</span> VWD Academy Advanced Course
                            </div>
                            <div className="faculty-name">
                                <h5>Jill Johnsen, MD</h5>
                            </div>
                            <p>Dr. Johnsen is a physician scientist with expertise in classical (benign) hematology. She is an Associate Professor of Medicine at the University of Washington, and she sees patients at the Washington Center for Bleeding Disorders (WACBD).
                            </p> 
                            <p>Dr. Johnsen studies the genetics and biology of variation in clotting factors and blood groups (blood types), with emphasis on coagulation factor VIII (FVIII), factor IX (FIX), and von Willebrand factor (VWF).{readMoreClicked8 == true ? <span>&nbsp;Dr. Johnsen is particularly interested to leverage these research efforts towards better understanding how bleeding uniquely impacts females. Dr. Johnsen’s research program is dedicated to improving the diagnosis and care of patients with blood disorders through advancement of our basic understanding of the underlying biology and through the translation of new knowledge and laboratory innovations to improve clinical testing. 
                            </span>:null}
                        {readMoreClicked8 == true ?  <Button onClick = {()=> {setReadMoreClicked8(false)}} variant="primary"> &lt; Read Less </Button>: <Button onClick = {()=> {setReadMoreClicked8(true)}} variant="primary">Read More &gt;</Button>} </p>
                            <div className="faculty-memder-image">
                                <img src="/images/jill.jpg"  alt="" />
                            </div>
                        </div>
                        <div className="faculty-list-box">
                            <div className="faculty-type">
                               Special GuesT <span>|</span> VWD Academy Advanced Course
                            </div>
                            <div className="faculty-name">
                                <h5>Jonathan Roberts, MD</h5>
                            </div>
                            <p>Jonathan C. Roberts, MD is the Associate Medical and Research Director at the Bleeding &amp; Clotting Disorders Institute and Assistant Professor of Pediatrics and Medicine at the University of Illinois College of Medicine at Peoria (UICOMP). After graduating from Southern Illinois University School of Medicine, he completed residency at UICOMP, and fellowship in Pediatric Hematology/Oncology/Blood&amp;Marrow Transplantation at the Medical College of Wisconsin.{readMoreClicked9 == true ? <span><br/><br/>
                            Dr. Roberts' research focuses in von Willebrand Disease, hemophilia, hemostasis, thrombosis, and classical hematology. He has received numerous young investigator research awards and has over 50 peer-reviewed research publications and abstracts including lead author publications in <i>Blood, the Journal of Thrombosis &amp; Haemostasis,</i> and the <i>New England Journal of Medicine.</i><br/><br/> Dr. Roberts has a unique passion for hematology as he is also an individual with severe hemophilia A.
                            </span>:null}
                        {readMoreClicked9 == true ?  <Button onClick = {()=> {setReadMoreClicked9(false)}} variant="primary"> &lt; Read Less </Button>: <Button onClick = {()=> {setReadMoreClicked9(true)}} variant="primary">Read More &gt;</Button>} </p>
                            <div className="faculty-memder-image">
                                <img src="/images/jonathan.jpg"  alt="" />
                            </div>
                        </div>
                        <div className="faculty-list-box">
                            <div className="faculty-type">
                               Special GuesT <span>|</span> VWD Academy Advanced Course
                            </div>
                            <div className="faculty-name">
                                <h5>Nathan T. Connell, MD, MPH, FACP</h5>
                            </div>
                            <p>Dr. Nathan Connell is Chief of Medicine at Brigham and Women's Faulkner Hospital, Vice Chair of Medicine at Brigham and Women's Hospital, Associate Director of the Boston Hemophilia Center, and Associate Professor of Medicine at Harvard Medical School.</p>
                            <p>His clinical practice focuses on inherited bleeding disorders and in addition to his clinical {readMoreClicked7 == true ? <span>&nbsp;practice in the Boston Hemophilia Center, he researches cost-effective strategies for diagnosis and management of hematologic disorders. <br/><br/>A graduate of Cornell University, the University of Miami School of Medicine, and the Harvard School of Public Health, he completed internal medicine residency and hematology/oncology fellowship at Brown University.<br/><br/>He is active with the American Society of Hematology, where he helped define and develop the field of systems-based hematology, which works to optimize care delivery systems for patients with blood disorders. He was co-chair of the ASH ISTH NHF WFH Guidelines on the Diagnosis and Management of von Willebrand disease, published in 2021.
                            </span>:null}
                        {readMoreClicked7 == true ?  <Button onClick = {()=> {setReadMoreClicked7(false)}} variant="primary"> &lt; Read Less </Button>: <Button onClick = {()=> {setReadMoreClicked7(true)}} variant="primary">Read More &gt;</Button>} </p>
                            <div className="faculty-memder-image">
                                <img src="/images/nathan.jpg"  alt="" />
                            </div>
                        </div>
                         <div className="faculty-list-box">
                            <div className="faculty-type">
                                Faculty member
                            </div>
                            <div className="faculty-name">
                                <h5>Afshan Idrees, MD, MBA, MPH</h5>
                            </div>
                            <p>Dr. Idrees is an Assistant Professor in the Department of Pathology at the University of Miami, and Medical Director of Hematology and Coagulation Laboratories. After receiving her medical degree from Rawalpindi Medical College in Pakistan, she came to United States and completed her Pathology residency at the University of South Florida, Tampa, followed by fellowships in Hematopathology at H. Lee Moffitt Cancer Center, Tampa, Transfusion Medicine at University of Florida, Jacksonville, and Laboratory {readMoreClicked5 == true ? <span>&nbsp;
                            Medicine at Memorial Sloan Kettering Cancer Center, NY. <br/><br/>She started her career at Emory University in Atlanta. In 2021, she moved to Miami and continued her work in Clinical Pathology. She loves to study disorders of thrombosis and hemostasis in both adult and pediatric populations. Her specific areas of interest are anticoagulation and bleeding diatheses, and her work has been mostly focused on standardization in the anticoagulation practices.<br/><br/>She is also interested in overall process improvement in the laboratories to improve quality of care for acutely ill patients.
                            </span>:null}
                        {readMoreClicked5 == true ?  <Button onClick = {()=> {setReadMoreClicked5(false)}} variant="primary"> &lt; Read Less </Button>: <Button onClick = {()=> {setReadMoreClicked5(true)}} variant="primary">Read More &gt;</Button>} </p>
                            <div className="faculty-memder-image">
                                <img src="/images/afshan.jpg"  alt="" />
                            </div>
                        </div>
                        <div className="faculty-list-box">
                            <div className="faculty-type">
                                Faculty member
                            </div>
                            <div className="faculty-name">
                                <h5>Michael J. Paidas, MD</h5>
                            </div>
                            <p>Dr. Michael J. Paidas is Professor and Chair, Department of Obstetrics and Gynecology, Miller School of Medicine, Chief of Service, Jackson Memorial Hospital, Jackson Health System, and Chief of Service, University Health Tower, University of Miami. Dr. Paidas joined the Jackson Health System and the U Miami Miller School of Medicine following a sixteen-year tenure at Yale School of Medicine where he was Professor and Vice Chair, Obstetrics, Gynecology, &amp; Reproductive Sciences. {readMoreClicked3 == true ? <span>
                                <p>He also was Director of the Maternal Fetal Medicine Fellowship, and Director of the Yale Women and Children's Center for Blood Disorders &amp; Preeclampsia Advancement, and Co-Director of the National Hemophilia Foundation-Baxter Clinical Fellowship at Yale. He has served as Interim Director, Section of Maternal Fetal Medicine, and Interim Section Chief, Maternal Fetal Medicine of Yale New Haven Hospital.</p> 
                                A recipient of the Headmaster's Scholarship, Dr. Paidas received his undergraduate degree from Fairfield University, cum laude. Subsequently, Dr. Paidas graduated from Tufts University School of Medicine. He completed his internship and residency in Obstetrics and Gynecology at Pennsylvania Hospital, Philadelphia, PA and a fellowship in Maternal Fetal Medicine at The Mount Sinai School of Medicine, New York, NY. After working at Tufts University School of Medicine and New York University, Dr. Paidas joined the Yale faculty in 2002, and he remains as Adjunct Professor, in the Department of Obstetrics, Gynecology and Reproductive Sciences.<br/>  
                                Dr. Paidas' career in medicine has focused generally on the fields of reproduction, perinatal medicine and hemostasis, with activities spanning patient care, translational research and clinical trials. Dr. Paidas is recognized as international authority in hemostasis disorders and women's health. He has authored or co-authored 126 peer reviewed articles and his work is published in a variety of scientific and clinical journals including PNAS, Pharmacology &amp; Therapeutics, Cell Death and Differentiation/Nature, and The Lancet. He has authored one book and 35 book chapters, 155 abstracts, and has given 300 presentations or courses. His research is supported by federal and non-federal agencies, including the NIH. 
                            </span>:null}
                        {readMoreClicked3 == true ?  <Button onClick = {()=> {setReadMoreClicked3(false)}} variant="primary"> &lt; Read Less </Button>: <Button onClick = {()=> {setReadMoreClicked3(true)}} variant="primary">Read More &gt;</Button>} </p>
                            <div className="faculty-memder-image">
                                <img src="/images/michael.jpg"  alt="" />
                            </div>
                        </div>
                        <div className="faculty-list-box">
                            <div className="faculty-type">
                                Faculty member
                            </div>
                            <div className="faculty-name">
                                <h5>David M. Andrews, MD</h5>
                            </div>
                            <p>Dr. David Andrews is a Clinical Associate Professor of Pathology &amp; As laboratory medical director his primary role is to ensure that a comprehensive quality management system is in place to provide accurate and timely results to clinicians for optimal patient care.Other clinical activities include maximizing laboratory test utilization and the role of the pathologist in the broader evaluation of the acquired and genetic basis of bleeding and thrombotic disorders.{readMoreClicked4 == true ? <span>
                                &nbsp;<br/><br/>He received his M.D. degree from the University of Miami Miller School of Medicine. Dr. Andrews performed his residency, fellowship in clinical pathology, and NIH postdoctoral fellowship at Harvard Medical School. He currently serves as Medical Director for the pathology laboratories at Jackson Memorial Hospital and as Director of Blood Bank/Transfusion Services for the UHealth system.<br/><br/>Dr. Andrews has authored or co-authored over 50 papers and his primary clinical and research interest is coagulation. 
                            </span>:null}
                        {readMoreClicked4 == true ?  <Button onClick = {()=> {setReadMoreClicked4(false)}} variant="primary"> &lt; Read Less </Button>: <Button onClick = {()=> {setReadMoreClicked4(true)}} variant="primary">Read More &gt;</Button>} </p>
                            <div className="faculty-memder-image">
                                <img src="/images/david.png"  alt="" />
                            </div>
                        </div>
                       
                        <div className="faculty-list-box">
                            <div className="faculty-type">
                                Faculty member
                            </div>
                            <div className="faculty-name">
                                <h5>Lesley Escobar BS, SU, MT</h5>
                            </div>
                            <p>Lesley Escobar is the supervisor of the Special Coagulation Laboratory at University of Miami, Miller School of Medicine.<br/><br/>The Special Coagulation Laboratory involves the areas of Haematology, Coagulation, Special Chemistry, Immunology and Molecular Pathology. She coordinates conversion in all Coagulation Areas of the UHealth facilities and coordinate standard supplies for this area.<br/><br/>
                            Among her responsibilities, she validates new tests and instruments: Aggregometers, Ristocetin {readMoreClicked6 == true ? <span>
                            &nbsp;cofactor, bringing manual ELISA to automated (PF4 IGG, Erythropoietin) Quantiferon, VWF Activity, etc.<br/><br/>
                            She is involved in training new employees in all areas of Special Coagulation Lab and Core Lab Coagulation area.
                            </span>:null}
                        {readMoreClicked6 == true ?  <Button onClick = {()=> {setReadMoreClicked6(false)}} variant="primary"> &lt; Read Less </Button>: <Button onClick = {()=> {setReadMoreClicked6(true)}} variant="primary">Read More &gt;</Button>} </p>
                            <div className="faculty-memder-image">
                                <img src="/images/lesley.jpg"  alt="" />
                            </div>
                        </div>

                        

                    </div>
                </Row>
            </Container>
        </div>
            <div className="facility">
                <Container>
                           <Row xs={2} md={4} className="g-4">
                                <Col>
                                <Card>
                                     <a href={"/MU/2022/course"}><Card.Img variant="top" src="/images/courses.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Course</Card.Title>
                                        <Card.Text>
                                           The VWD Academy is organized for and offered to participants with different levels of knowledge of von Willebrand disease. Learn more about the Basic and Advanced courses. 
                                        </Card.Text>
                                        <a href={"/MU/2022/course"}>View</a>
                                    </Card.Body>
                                </Card>
                                </Col>
                                <Col>
                                <Card>
                                    <a href={"/MU/2022/faculty"}><Card.Img variant="top" src="/images/pediatric_group_photo2.jpg" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Faculty</Card.Title>
                                        <Card.Text>
                                        Meet the VWD Academy Faculty. Our experts bring their broad experience in clinical and laboratory practice to provide you with high-quality courses.
                                        </Card.Text>
                                        <a href={"/MU/2022/faculty"}>View</a>
                                    </Card.Body>
                                </Card>
                                </Col>
                            <Col>
                                <Card>
                                    <a href={"/MU/2022/testimonial"}><Card.Img variant="top" src="/images/testimonial.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Testimonials</Card.Title>
                                        <Card.Text>
                                        The VWD Academy is a highly interactive and stimulating event. Our participants’ feedback is very important for improving our Academy. Read here what people say about us. 
                                        </Card.Text>
                                        <a href={"/MU/2022/testimonial"}>View</a>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <a href ="https://vwdtest.com/" target="_blank"><Card.Img variant="top" src="/images/vwd-test.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>VWDtest.com</Card.Title>
                                        <Card.Text>
                                        The VWDtest.com website is a resource to support people who suspect that they may have VWD and the physicians who manage them.  
                                        </Card.Text>
                                        <a href ="https://vwdtest.com/" target="_blank">View</a>
                                    </Card.Body>
                                </Card>
                            </Col>
                    </Row>
                </Container>
            </div>
        
        </>
    )
}
export default Faculty;