import React, { useState, useEffect, useRef } from 'react'
import { Button, Card,  Col, Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
const About = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const videoEl = useRef(null);
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
    return (
        <>
            <div className="banner">
                <div className="video-banner2">
                    <video playsInline loop muted
                        alt="VWD Video"
                        src="/images/landing_video.mp4"
                        ref={videoEl}
                        />
                </div>
                <Container>
                    <Row>
                        <div className="banner-over">
                            <div className="banner-logo-icon">
                                 <img src="/images/banner-symbol.svg" alt="Logo Symbol" />
                            </div>
                            <div className="banner-logo-logo">
                                <img src="/images/banner-logo.svg" alt="Banner Logo" />
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>

            <div className="vwd-academy">
                 <Container>
                    <Row>
                        <div className="vwd-academy-block">
                            <h2>VwD Academy</h2>
                            <div className="vwd-academy-box">
                                <h6>The VWD Academy is an educational event organized by the University of Miami Hemophilia Treatment Center with educational grant support from and in collaboration with Octapharma AG.</h6> 
                                <h6>The main objective of this two-day workshop is to help to improve the awareness, diagnosis, and treatment of von Willebrand disease (VWD) in the Latin America region. </h6>
                                <h6 className="color">The VWD Academies take place at the University of Miami in Florida, USA.</h6>
                            </div>
                        </div>
                    </Row> 
                </Container>
            </div>
             <div className="vwd-academy-faculty">
                 <Container>
                    <Row>
                        <div className="director-welcome">
                             <h2>Director's Welcome Video for Participants</h2>
                             <div className="director-video" onClick={handleShow}>
                                 <video id="myVideo" width="100%" poster="/images/video-poster.png">
                                    <source src="https://docintel.s3.eu-west-1.amazonaws.com/video/video/VWD_Academy_Welcome_Video_Final.mp4"
                                    />
                                    Sorry, your browser doesn't support videos.
                                 </video>
                                 <div className="video_play_btn"> <img src="/images/play_btn.png" alt=""/></div>
                                {/* <ReactPlayer url='https://docintel.s3.eu-west-1.amazonaws.com/video/video/VWD_Academy_Welcome_Video_Final.mp4' /> */}
                             </div>
                        </div>
                    </Row> 
                    <div className="participant-contact">
                        <Row>
                            <Col>
                                <div className="apply-box">
                                    <div className="block">
                                        <div className="step-process">
                                            <div className="step-process-img">
                                                <img src="/images/contact_us.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="process-desc">
                                            <h5 className="top_title">01</h5>
                                            <h5>Contact us</h5>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="apply-box">
                                    <div className="block">
                                        <div className="step-process">
                                            <div className="step-process-img">
                                                <img src="/images/attended.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="process-desc">
                                             <h5 className="top_title">02</h5>
                                            <h5>Attend and take part</h5>
                                        </div>
                                    </div>
                                </div>
                            </Col>   
                            <Col>
                                <div className="apply-box">
                                    <div className="block">
                                        <div className="step-process">
                                            <div className="step-process-img">
                                                <img src="/images/course-event.svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="process-desc">
                                             <h5 className="top_title">03</h5>
                                            <h5>Get all course materials after the event</h5>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                    </Row>
                    </div>
                </Container>
            </div>
            <div className="event_apply">
                <Container>
                    <Row>
                        <div className="event-apply-box">
                            <h6 className="event-top-title">Are you interested in learning more about von Willebrand disease and participating in one of the VWD Academies?
The course is targeted at healthcare professionals in the area of hemostasis with a special focus on von Willebrand disease patients and their management in clinical practice. </h6>
                            <h6>Contact us for more information about the next and future Academies program and to learn <br /> how to participate.
                            </h6>
                            <a href="mailto:vwdacademy@docintel.app">Contact us</a>
                        </div>
                    </Row>
                </Container>
                <div className="basic-block-sec"></div>
            </div>
            <div className="facility">
                <Container>
                           <Row xs={2} md={4} className="g-4">
                                <Col>
                                <Card>
                                     <a href={"/MU/2022/course"}><Card.Img variant="top" src="/images/courses.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Course</Card.Title>
                                        <Card.Text>
                                           The VWD Academy is organized for and offered to participants with different levels of knowledge of von Willebrand disease. Learn more about the Basic and Advanced courses. 
                                        </Card.Text>
                                        <a href={"/MU/2022/course"}>View</a>
                                    </Card.Body>
                                </Card>
                                </Col>
                                <Col>
                                <Card>
                                    <a href={"/MU/2022/faculty"}><Card.Img variant="top" src="/images/pediatric_group_photo2.jpg" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Faculty</Card.Title>
                                        <Card.Text>
                                        Meet the VWD Academy Faculty. Our experts bring their broad experience in clinical and laboratory practice to provide you with high-quality courses.
                                        </Card.Text>
                                        <a href={"/MU/2022/faculty"}>View</a>
                                    </Card.Body>
                                </Card>
                                </Col>
                            <Col>
                                <Card>
                                    <a href={"/MU/2022/testimonial"}><Card.Img variant="top" src="/images/testimonial.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Testimonials</Card.Title>
                                        <Card.Text>
                                        The VWD Academy is a highly interactive and stimulating event. Our participants’ feedback is very important for improving our Academy. Read here what people say about us. 
                                        </Card.Text>
                                        <a href={"/MU/2022/testimonial"}>View</a>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <a href ="https://vwdtest.com/" target="_blank"><Card.Img variant="top" src="/images/vwd-test.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>VWDtest.com</Card.Title>
                                        <Card.Text>
                                        The VWDtest.com website is a resource to support people who suspect that they may have VWD and the physicians who manage them.  
                                        </Card.Text>
                                        <a href ="https://vwdtest.com/" target="_blank">View</a>
                                    </Card.Body>
                                </Card>
                            </Col>
                    </Row>
                </Container>
            </div>
             <Modal show={show} onHide={handleClose} id="courses_video" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <video controls width="100%" poster="video-poster.png" autoPlay="true">
                        <source src="https://docintel.s3.eu-west-1.amazonaws.com/video/video/VWD_Academy_Welcome_Video_Final.mp4"/>
                    </video>
                </Modal.Body>
            </Modal>
        
        </>
    )
}
export default About;