import React from "react";
import { Row ,Container, Col} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
const Footer = () => {
    const location = useLocation();
    return (
      <>
      {location.pathname==="/MU/2022/register" ? null:
      <footer>
        <Container>
            <Row>
                <Col className="left-sec">
                    <div className="footer-content">
                      <div className="footer_logo">
                        <Link to={"/MU/2022/about"}><img src="/images/footer-logo.svg" alt="Logo" /></Link>
                      </div>
                      <div className="footer-content-link">
                        <ul>
                            <li>
                                <a href="https://www.youtube.com/channel/UCYgtXBu91QV8lGB1P6vIbCA" target="_blank">
                                <img src="/images/fb-icon.svg" alt="Facebook" /></a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/octapharma/" target="_blank">
                                <img src="/images/linkedin-icon.svg" alt="LinkedIn" /></a>
                            </li>
                        </ul>
                         <p>Funded and facilitated by Octapharma AG</p>
                      </div>
                      <div className="footer-privacy-link">
                        <ul>
                            <li><a href="mailto:vwdacademy@docintel.app">Contact Us</a></li>|
                            <li><a href={"/MU/2022/legal"} >Legal Statement</a></li>|
                            <li><a href ={"/MU/2022/privacy"}>Data Privacy Statement</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="copy_right">
                        Last Updated: March 2023
                    </div>
                </Col>
                <Col className="right-sec">
                    <a href="https://med.miami.edu/departments/pediatrics/divisions/hematology-oncology/hemophilia-treatment-center" target="_blank">
                        <img src="/images/footer-icon-new.png" alt="Footer Image" />
                    </a>
                </Col>
            </Row>
        </Container>
      </footer>}
    </>)
}
export default Footer;