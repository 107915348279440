import React, { useState } from 'react'
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
const Course = () => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState();
    const handleClose = () => setShow(false);
    const handleShow = (data) =>{
        setShow(true);
        setData(data);
    } 
    return (
        <>
        <div className="our-courses">
            <Container>
                <Row>
                    <div className="course-detail">
                        <h2>Next course details</h2>
                        <div className="course-detail-box d-flex justify-content-between">
                            <div className="course-description">
                                <h6>The next available VWD Academy will be an Advanced Course, and our Academy Director Dr. Fernando Corrales-Medina have invited three special guests:</h6>
                                <ul>
                                    <li>Dr. Jill Johnsen <span>|</span> Associate Professor, University of Washington</li>
                                    <li>Dr. Jonathan Roberts <span>|</span> Associate Medical Director, Bleeding & Clotting Disorders Institute in Peoria</li>
                                    <li>Dr. Nathan Connell <span>|</span> Associate Professor, Medicine, Harvard Medical School</li>
                                </ul>
                                <div className="office-locator">
                                    <div className="course-timimg d-flex align-items-center">
                                        <img src="/images/calendar.svg" alt="" />
                                        <h6>01 - 02 March 2023</h6>
                                    </div>
                                    <div className="office-location d-flex align-items-center">
                                        <img src="/images/location.svg" alt="" />
                                        <h6>University of Miami Hemophilia Treatment Center, Coral Gables, Florida</h6>
                                    </div>
                                </div>
                                <div className="btn-action">
                                    <Button variant="primary" className="filled"><a href="mailto:vwdacademy@docintel.app">Contact Us</a></Button>
                                     
                                    <Button variant="primary" className="bordered"><a href="/images/VWD-Academy-2023-Advance-Course-Agenda.pdf" download="VWD Academy 2023 Advance Course Agenda">Event Agenda</a></Button>
                                </div>
                            </div>
                            <div className="course-related-img">
                                <img src="/images/course-detail-img.png" alt="" />
                            </div>
                        </div>
                    </div>
                
                    <div className="education-program">
                         <h2>Educational Program</h2> 
                         <h6>Taking into account the latest thinking in adult learing, the Faculty have developed a interactive and engaging course agenda which will address clinical practice and laboratory testing needs of health care professionals involved in the diagnose and treatment of VWD patients.</h6>
                         <div className="education-block">
                            <h5>The VWD Academy curriculum is divided into two different type of workshops:</h5>
                            <ul>
                                <li><span>“The Basic Course”</span> is focused on providing information on the core aspects of VWD, including clinical characteristics, diagnosis, treatment, etc., to healthcare professionals with minimal experience in VWD (including physicians, nurses, pharmacists, etc.)  </li>
                                <li><span>“The Advance Course”</span> targets hematologists with a proven track record of experience in managing VWD patients (members of hemophilia treatment centers). During the Advanced Course, more in-depth and specialized topics are discussed.</li>
                            </ul>
                         </div>
                    </div>

                </Row>
            </Container>
        </div>
        <div className="learm-course-steps">
            <Container>
                <Row>
                    <div className="learn-course-boxes d-flex">
                        <h5>The VWD Academy provides highly scientific and practical educational content using a number of different formats:</h5>
                        <Col>
                            <div className="course-box">
                                <div className="course-box-img">
                                    <img src="/images/face-to-face.svg" alt="" />
                                    <span className="img-marker">&nbsp;</span>
                                </div>
                                <div className="course-box-description">
                                    <h5> Face-to-face lectures</h5>
                                    <p>Including interactive Q&amp;A sessions </p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="course-box">
                                <div className="course-box-img">
                                    <img src="/images/workshops.svg" alt="" />
                                    <span className="img-marker">&nbsp;</span>
                                </div>
                                <div className="course-box-description">
                                    <h5>Networking</h5>
                                    <p>Connect with participants and Faculty to broaden your network</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="course-box">
                                <div className="course-box-img">
                                    <img src="/images/translation.svg" alt="" />
                                    <span className="img-marker">&nbsp;</span>
                                </div>
                                <div className="course-box-description">
                                    <h5>Simultaneous Spanish translations</h5>
                                    <p>Benefit from content delivered in Spanish</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="course-box">
                                <div className="course-box-img">
                                    <img src="/images/workshop.svg" alt="" />
                                    <span className="img-marker">&nbsp;</span>
                                </div>
                                <div className="course-box-description">
                                    <h5>Workshop in the coagulation laboratory</h5>
                                    <p>Learn more about VWD diagnosis</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="course-box">
                                <div className="course-box-img">
                                    <img src="/images/case-study.svg" alt="" />
                                    <span className="img-marker">&nbsp;</span>
                                </div>
                                <div className="course-box-description">
                                    <h5>Participant case studies discussion</h5>
                                    <p>Take the opportunity to share your experience</p>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Row>
            </Container>
        </div>
        <div className="unmet-needs">
            <Container>
                <Row>
                    <div className="unmet-needs-inset" id="unmet-needs-inset">
                        <h2>unmet needs of von willebrand Disease</h2>
                        <h6>Learn more from our Director Fernando Corrales-Medina about the unmet needs related to lack of awareness and consequent underdiagnosis of von Willebrand disease.</h6>
                    </div>
                    <div className="umnet-lessons d-flex">
                        <Col>
                            <div className="video-sec">
                                <h6>How common is underdiagnosis of von Willebrand disease?</h6>
                                <div className="video-chapter">
                                    <img src="/images/pediatric1.png" alt=""/>
                                    <div className="video-btn" onClick={()=>handleShow(1)}>
                                        <img src="/images/play_btn.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="video-sec">
                                <h6>Why are many people with VWD underdiagnosed?</h6>
                                <div className="video-chapter">
                                    <img src="/images/pediatric2.png" alt=""/>
                                    <div className="video-btn" onClick={()=>handleShow(2)}>
                                        <img src="/images/play_btn.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="video-sec">
                                <h6>Why it is so important to improve awareness of VWD?</h6>
                                <div className="video-chapter">
                                    <img src="/images/pediatric3.png" alt=""/>
                                    <div className="video-btn" onClick={()=>handleShow(3)}>
                                        <img src="/images/play_btn.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="video-sec">
                                <h6>What are the most common presenting symptoms of VWD?</h6>
                                <div className="video-chapter">
                                    <img src="/images/pediatric4.png" alt=""/>
                                    <div className="video-btn" onClick={()=>handleShow(4)}>
                                        <img src="/images/play_btn.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="video-sec">
                                <h6>What are the consequences of undiagnosed VWD?</h6>
                                <div className="video-chapter">
                                    <img src="/images/pediatric5.png" alt=""/>
                                    <div className="video-btn" onClick={()=>handleShow(5)}>
                                        <img src="/images/play_btn.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="video-sec">
                                <h6>How can we increase awareness of VWD?</h6>
                                <div className="video-chapter">
                                    <img src="/images/pediatric6.png" alt=""/>
                                    <div className="video-btn" onClick={()=>handleShow(6)}>
                                        <img src="/images/play_btn.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Row>
            </Container>
        </div>
            <div className="facility">
                <Container>
                           <Row xs={2} md={4} className="g-4">
                                <Col>
                                <Card>
                                     <a href={"/MU/2022/course"}><Card.Img variant="top" src="/images/courses.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Course</Card.Title>
                                        <Card.Text>
                                           The VWD Academy is organized for and offered to participants with different levels of knowledge of von Willebrand disease. Learn more about the Basic and Advanced courses. 
                                        </Card.Text>
                                        <a href={"/MU/2022/course"}>View</a>
                                    </Card.Body>
                                </Card>
                                </Col>
                                <Col>
                                <Card>
                                    <a href={"/MU/2022/faculty"}><Card.Img variant="top" src="/images/pediatric_group_photo2.jpg" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Faculty</Card.Title>
                                        <Card.Text>
                                        Meet the VWD Academy Faculty. Our experts bring their broad experience in clinical and laboratory practice to provide you with high-quality courses.
                                        </Card.Text>
                                        <a href={"/MU/2022/faculty"}>View</a>
                                    </Card.Body>
                                </Card>
                                </Col>
                            <Col>
                                <Card>
                                    <a href={"/MU/2022/testimonial"}><Card.Img variant="top" src="/images/testimonial.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Testimonials</Card.Title>
                                        <Card.Text>
                                        The VWD Academy is a highly interactive and stimulating event. Our participants’ feedback is very important for improving our Academy. Read here what people say about us. 
                                        </Card.Text>
                                        <a href={"/MU/2022/testimonial"}>View</a>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <a href ="https://vwdtest.com/" target="_blank"><Card.Img variant="top" src="/images/vwd-test.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>VWDtest.com</Card.Title>
                                        <Card.Text>
                                        The VWDtest.com website is a resource to support people who suspect that they may have VWD and the physicians who manage them.  
                                        </Card.Text>
                                        <a href ="https://vwdtest.com/" target="_blank">View</a>
                                    </Card.Body>
                                </Card>
                            </Col>
                    </Row>
                </Container>
            </div>
        <Modal show={show} onHide={handleClose} id="courses_video" centered>
             <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <video controls width="100%" poster="video-poster.png" autoPlay="true">
                    <source src={data == 1 ? "https://docintel.s3.eu-west-1.amazonaws.com/video/video/Corrales_Q1.mp4":data == 2?"https://docintel.s3.eu-west-1.amazonaws.com/video/video/Corrales_Q2.mp4":data==3?"https://docintel.s3.eu-west-1.amazonaws.com/video/video/Corrales_Q4.mp4":data == 4?"https://docintel.s3.eu-west-1.amazonaws.com/video/video/Corrales_Q3.mp4":data == 5 ?"https://docintel.s3.eu-west-1.amazonaws.com/video/video/Corrales_Q5.mp4":data==6?"https://docintel.s3.eu-west-1.amazonaws.com/video/video/Corrales_Q6.mp4":null}/>
                </video>
            </Modal.Body>
        </Modal>

        </>
    )
} 
export default Course;