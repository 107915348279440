import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";

const Testimonial = () => {
    const [active,setActive]=useState()

    const activeClassAdd=(index)=>{
        setActive(index)
    }
    let path_image = process.env.REACT_APP_Miami_website_image;
    
    return(
        <>
        <div className="testimonial">
            <Container>
                <Row>
                    <div className="testimonial-section-title">
                        <h2>What People Say About Us</h2>
                    </div>
                </Row>
            </Container>
            <div className="vwd-academy testimonial_sec">
                 <Container>
                    <Row>
                        <div className="vwd-academy-block">
                            <div className="vwd-academy-box">
                               <Carousel showThumbs={false} showArrows={false} showStatus={false} carousel-status={false} emulateTouch={true} infiniteLoop={true} autoPlay={true}>
                                    <div>
                                        <p className="legend">“Excelente experiencia. Nos dieron mucos elementos para poder tener un mejor abordaje del tratamiento de EVW en nuestros países”</p>
                                    </div>
                                    <div>
                                        <p className="legend">“Muy organizado y completo el temario”</p>
                                    </div>
                                    
                                    <div>
                                        <p className="legend">“Muy interesante y didáctico”</p>
                                    </div>
                                    <div>
                                        <p className="legend">“I was able to resolve a lot of my doubts”</p>
                                    </div>
                                    <div>
                                        <p className="legend">“Excelente experiencia. Nos dieron mucos elementos para poder tener un mejor abordaje del tratamiento de EVW en nuestros países”</p>
                                    </div>
                                    <div>
                                        <p className="legend">“Excelente profesionalismo, nivel técnico y sincronización”</p>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </Row> 
                </Container>
            </div>
            <div className="people-feedback">
                <Container>
                    <Row>
                        <div className="feedback-box">
                            <div className="feedback-box-inset">
                                <div className="feedback-box-icon">
                                    <div className="feedback-icon">
                                        <img src="/images/feedback.svg" alt="" />
                                    </div>
                                </div>
                                <div className="feedback-detail">
                                    <h3>We love to receive feedback from our attendees!</h3>
                                    <h5>Please send us your feedback and comments via <span><a href="mailto:vwdacademy@docintel.app">email</a></span>.</h5>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            <div className="academy-survey survay-2023 no-padding">
                <Container>
                    <Row>
                        <h2>PREVIOUS VWD ACADEMY SURVEY RESULTS</h2>
                        <p>During each event, we measure the Academy's success by collecting participant feedback.<br/>
                            The goal is to assess the quality of the educational event and to be sure that we are delivering the right content to help to improve the diagnosis and management of von Willebrand disease.</p>
                        <div className="academy-survey no-padding survey-2023">
                            <div className="basic-survey">
                            <h3>2022 Advanced Course</h3>
                            <div className="g-4 row row-cols-md-2 row-cols-1">
                                <Col >
                                    <div onClick={()=>activeClassAdd(1)} className={active===1?"basic-block active":"basic-block"} >
                                        <p>How likely is it that you would recommend this academy to a colleague currently working with patients in the bleeding disorders community?</p>
                                        <div className="survey-result">
                                            <div className="survey-result-view">
                                                <span>Not at all likely</span>
                                                <span>Extremely likely</span>
                                                <div className="survey-result-processbar">
                                                    <span>1</span>
                                                    <span className="result-processbar">&nbsp;</span>
                                                    <span>10</span>
                                                </div>
                                            </div>
                                            <div className="average-result">
                                                <h5>Extremely Likely <span>10</span></h5>
                                            </div>
                                        </div>
                                        <div className="result-process">
                                            <div className="result-process-left">
                                                <div className="result-process-icon">
                                                    <div className="result-icon">
                                                        <img src="/images/group-survey.svg" alt="" />
                                                        <span>10</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="result-process-right">
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>10</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>10</span></p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                </Col>
                                <Col>
                                    <div onClick={()=>activeClassAdd(2)} className={active===2?"basic-block option active":"basic-block option"}>
                                        <p>Overall, how would you rate the VWD Academy for Latin America-Advanced workshop?</p>
                                        <div className="survey-result">
                                             <div className="survey-result-view">
                                                <div><span>Fair</span></div>
                                                <div><span>Poor</span></div>
                                                <div><span>Good</span></div>
                                                <div><span>Very good</span></div>
                                                <div><span>Excellent</span></div>
                                                <div className="survey-result-processbar">
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="result-process">
                                            <div className="result-process-left">
                                                <div className="result-process-icon">
                                                    <div className="result-icon">
                                                        <img src="/images/group-survey.svg" alt="" />
                                                        <span>10</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="result-process-right">
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>10</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>Excellent</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                        </div>
                    </Row>
                </Container>
                <div className="basic-block-sec"></div>
            </div>
             <div className="academy-survey">
                <Container>
                    <Row>
                        <div className="basic-survey">
                            <h3>2019 Basic Course</h3>
                            <div className="g-4 row row-cols-md-2 row-cols-1">
                                <Col >
                                    <div onClick={()=>activeClassAdd(1)} className={active===1?"basic-block active":"basic-block"} >
                                        <p>How likely is it that you would recommend this Academy to a colleague currently working with patients in the bleeding disorders community?</p>
                                        <div className="survey-result">
                                            <div className="survey-result-view">
                                                <span>Not at all likely</span>
                                                <span>Extremely likely</span>
                                                <div className="survey-result-processbar">
                                                    <span>1</span>
                                                    <span className="result-processbar">&nbsp;</span>
                                                    <span>10</span>
                                                </div>
                                            </div>
                                            <div className="average-result">
                                                <h5>Average <span>9.6</span></h5>
                                            </div>
                                        </div>
                                        <div className="result-process">
                                            <div className="result-process-left">
                                                <div className="result-process-icon">
                                                    <div className="result-icon">
                                                        <img src="/images/group-survey.svg" alt="" />
                                                        <span>10</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="result-process-right">
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>7</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>10</span></p>
                                                    </div>
                                                </div>
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>2</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>9</span></p>
                                                    </div>
                                                </div>
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>1</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>8</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="average-result">
                                            <h5>Average <span>9.6</span></h5>
                                        </div> */}
                                    </div>
                                </Col>
                                <Col>
                                    <div onClick={()=>activeClassAdd(2)} className={active===2?"basic-block option active":"basic-block option"}>
                                        <p>Overall, how would you rate the VWD Academy for Latin America-Basic workshop?</p>
                                        <div className="survey-result">
                                            <div className="survey-result-view">
                                                <div><span>Fair</span></div>
                                                <div><span>Poor</span></div>
                                                <div><span>Good</span></div>
                                                <div><span>Very good</span></div>
                                                <div><span>Excellent</span></div>
                                                <div className="survey-result-processbar">
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="result-process">
                                            <div className="result-process-left">
                                                <div className="result-process-icon">
                                                    <div className="result-icon">
                                                        <img src="/images/group-survey.svg" alt="" />
                                                        <span>10</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="result-process-right">
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>8</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>Excellent</span></p>
                                                    </div>
                                                </div>
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>2</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>Very good</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Container>
                <div className="basic-block-sec"></div>
            </div>
            <div className="academy-survey no-padding">
                <Container>
                    <Row>
                        <div className="basic-survey">
                            <h3>2019 Advanced Course</h3>
                            <div className="g-4 row row-cols-md-2 row-cols-1">
                                <Col >
                                    <div onClick={()=>activeClassAdd(1)} className={active===1?"basic-block active":"basic-block"} >
                                        <p>How likely is it that you would recommend this Academy to a colleague currently working with patients in the bleeding disorders community?</p>
                                        <div className="survey-result">
                                            <div className="survey-result-view">
                                                <span>Not at all likely</span>
                                                <span>Extremely likely</span>
                                                <div className="survey-result-processbar">
                                                    <span>1</span>
                                                    <span className="result-processbar">&nbsp;</span>
                                                    <span>10</span>
                                                </div>
                                            </div>
                                            <div className="average-result">
                                                <h5>Average <span>9.6</span></h5>
                                            </div>
                                        </div>
                                        <div className="result-process">
                                            <div className="result-process-left">
                                                <div className="result-process-icon">
                                                    <div className="result-icon">
                                                        <img src="/images/group-survey.svg" alt="" />
                                                        <span>14</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="result-process-right">
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>10</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>10</span></p>
                                                    </div>
                                                </div>
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>2</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>9</span></p>
                                                    </div>
                                                </div>
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>2</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>8</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </Col>
                                <Col>
                                    <div onClick={()=>activeClassAdd(2)} className={active===2?"basic-block option active":"basic-block option"}>
                                        <p>Overall, how would you rate the VWD Academy for Latin America-Advanced workshop?</p>
                                        <div className="survey-result">
                                             <div className="survey-result-view">
                                                <div><span>Fair</span></div>
                                                <div><span>Poor</span></div>
                                                <div><span>Good</span></div>
                                                <div><span>Very good</span></div>
                                                <div><span>Excellent</span></div>
                                                <div className="survey-result-processbar">
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="result-process">
                                            <div className="result-process-left">
                                                <div className="result-process-icon">
                                                    <div className="result-icon">
                                                        <img src="/images/group-survey.svg" alt="" />
                                                        <span>14</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="result-process-right">
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>10</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>Excellent</span></p>
                                                    </div>
                                                </div>
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>4</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>Very good</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Container>
                <div className="basic-block-sec"></div>
            </div>
            {/* <div className="academy-survey no-padding survey-2023">
                <Container>
                    <Row>
                        <div className="basic-survey">
                            <h3>2022 Advanced Course</h3>
                            <div className="g-4 row row-cols-md-2 row-cols-1">
                                <Col >
                                    <div onClick={()=>activeClassAdd(1)} className={active===1?"basic-block active":"basic-block"} >
                                        <p>How likely is it that you would recommend this academy to a colleague currently working with patients in the bleeding disorders community?</p>
                                        <div className="survey-result">
                                            <div className="survey-result-view">
                                                <span>Not at all likely</span>
                                                <span>Extremely likely</span>
                                                <div className="survey-result-processbar">
                                                    <span>1</span>
                                                    <span className="result-processbar">&nbsp;</span>
                                                    <span>10</span>
                                                </div>
                                            </div>
                                            <div className="average-result">
                                                <h5>Extremely Likely <span>10</span></h5>
                                            </div>
                                        </div>
                                        <div className="result-process">
                                            <div className="result-process-left">
                                                <div className="result-process-icon">
                                                    <div className="result-icon">
                                                        <img src="/images/group-survey.svg" alt="" />
                                                        <span>10</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="result-process-right">
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>10</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>10</span></p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                </Col>
                                <Col>
                                    <div onClick={()=>activeClassAdd(2)} className={active===2?"basic-block option active":"basic-block option"}>
                                        <p>Overall, how would you rate the VWD Academy for Latin America-Advanced workshop?</p>
                                        <div className="survey-result">
                                             <div className="survey-result-view">
                                                <div><span>Fair</span></div>
                                                <div><span>Poor</span></div>
                                                <div><span>Good</span></div>
                                                <div><span>Very good</span></div>
                                                <div><span>Excellent</span></div>
                                                <div className="survey-result-processbar">
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                    <div><span>&nbsp;</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="result-process">
                                            <div className="result-process-left">
                                                <div className="result-process-icon">
                                                    <div className="result-icon">
                                                        <img src="/images/group-survey.svg" alt="" />
                                                        <span>10</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="result-process-right">
                                                <div className="result-process-box">
                                                    <div className="result-icon">
                                                        <span>10</span>
                                                        <img src="/images/single-survey.svg" alt="" />
                                                    </div>
                                                    <div className="result-rated">
                                                        <p>Rated <span>Excellent</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Container>
                <div className="basic-block-sec"></div>
            </div> */}
            <div className="academy-faculty-slider">
                <Container>
                    <Row>
                        <div className="vwd-academy-block">
                            <div className="vwd-academy-box">
                               <Carousel showThumbs={false} showArrows={false} showStatus={false} carousel-status={false} emulateTouch={true} infiniteLoop={true} autoPlay={true}>
                                    <div>
                                      <img src="/images/faculty-img1.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/images/faculty-img2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/images/faculty-img3.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/images/faculty-img4.jpg" alt="" />
                                    </div>
                                    <div>
                                         <img src="/images/faculty-img5.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/images/faculty-img6.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/images/faculty-img7.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/images/faculty-img8.jpg" alt="" />
                                    </div>
                                    <div>
                                         <img src="/images/faculty-img9.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/images/faculty-img10.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </Row> 
                </Container>
            </div>
            <div className="facility">
                <Container>
                           <Row xs={2} md={4} className="g-4">
                                <Col>
                                <Card>
                                     <a href={"/MU/2022/course"}><Card.Img variant="top" src="/images/courses.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Course</Card.Title>
                                        <Card.Text>
                                           The VWD Academy is organized for and offered to participants with different levels of knowledge of von Willebrand disease. Learn more about the Basic and Advanced courses. 
                                        </Card.Text>
                                        <a href={"/MU/2022/course"}>View</a>
                                    </Card.Body>
                                </Card>
                                </Col>
                                <Col>
                                <Card>
                                    <a href={"/MU/2022/faculty"}><Card.Img variant="top" src="/images/pediatric_group_photo2.jpg" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Faculty</Card.Title>
                                        <Card.Text>
                                        Meet the VWD Academy Faculty. Our experts bring their broad experience in clinical and laboratory practice to provide you with high-quality courses.
                                        </Card.Text>
                                        <a href={"/MU/2022/faculty"}>View</a>
                                    </Card.Body>
                                </Card>
                                </Col>
                            <Col>
                                <Card>
                                    <a href={"/MU/2022/testimonial"}><Card.Img variant="top" src="/images/testimonial.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>Testimonials</Card.Title>
                                        <Card.Text>
                                        The VWD Academy is a highly interactive and stimulating event. Our participants’ feedback is very important for improving our Academy. Read here what people say about us. 
                                        </Card.Text>
                                        <a href={"/MU/2022/testimonial"}>View</a>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <a href ="https://vwdtest.com/" target="_blank"><Card.Img variant="top" src="/images/vwd-test.png" alt=""/></a>
                                    <Card.Body>
                                        <Card.Title>VWDtest.com</Card.Title>
                                        <Card.Text>
                                        The VWDtest.com website is a resource to support people who suspect that they may have VWD and the physicians who manage them.  
                                        </Card.Text>
                                        <a href ="https://vwdtest.com/" target="_blank">View</a>
                                    </Card.Body>
                                </Card>
                            </Col>
                    </Row>
                </Container>
            </div>
            
        </div>
        </>
    )

}

export default Testimonial;



