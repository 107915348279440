import React, { useState, useEffect,useRef } from "react";
import { Button, Col, Container, Dropdown,DropdownButton, Row } from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import axios from "axios";
import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';

const Register = () => {
let path_image = process.env.REACT_APP_Miami_website_image;
const [validator] = React.useState(new SimpleReactValidator());
const [username, setUsername] = useState("");
const [email, setEmail] = useState("");
const [consent, setConsent] = useState([]);
const [renderAfterValidation, setRenderAfterValidation] = useState(0);
const inputEl = useRef(null);
const [loading, setLoading] = useState(false);
const [noemail, setNoemail] = useState(false);
const searched  = window.location.search;
const params = new URLSearchParams(searched);

const handlenoemail = () => {
    setNoemail(false);
}

const handleRegister = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
        setLoading(true)
        axios.defaults.baseURL = "https://webinar.docintel.app/flow/EventRegister/RegisterUser";
        let body= {
            speciality:"0",
            country:"0",
            attend_networking_dinner:0,
            require_accommodation: 0,
            eventId:378,
            userEmail:email,
            province: "0",
            userName: username,
            companyId: 2147499629,
            consent: consent.join("~")
        }
        await axios
          .post(`signup`, body)
          .then((res) => {
            if (res.data.message == "User registered successfully") {
               setLoading(false)
               setNoemail(true)
               var user_id = res.data.data.user_id
               setTimeout(() => {
                window.location = "https://vwdacademy.docintel.app/redirect-to-upload/378/"+user_id
              }, "3000")
              
              // setShow(false);
            } else {
                setLoading(false)
                alert(res.data.message);
             
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }else{
        validator.showMessages();
        setRenderAfterValidation(renderAfterValidation + 1);
    }

}

    const getDetais =async ()=>{

        let body = {
            user_id: params.get("rtcf-id") ? params.get("rtcf-id") : "",
          };
          await axios
            .post(
              `https://webinar.docintel.app/flow/EventRegister/Userdetail`,
              body
            )
            .then((res) => {
              
              if (res.data.message === "Data fetched successfully.") {
                    setEmail(res.data.data.email)
                    setUsername(res.data.data.name)
              
                
              }
            })
            .catch((err) => {
              console.log(err);
            });

    }

 const consentchecked =(e) =>{
   
    let consent_temp = consent;
    if(consent_temp.includes(e.target.value)){
        consent_temp =  consent_temp.filter(arrayItem => arrayItem !== e.target.value);
    
    }else{
        consent_temp[consent_temp.length] = e.target.value
    }

    setConsent(consent_temp)
    console.log()

 }

const userChanged = e=>{
    setUsername(e.target.value);
}
const emailChanged = e=>{
    setEmail(e.target.value);
}

useEffect(() => {
        getDetais()
  }, []);

return (
    <>
     {loading ? (
            <div className="loader-container">
                <div className="spinner"></div>
            </div>
            ) : ""
        }
    <div className="login-vwd-academy">
        <Container>
            <div className="factor-season-inner">
                <Row>
                    <div className="col-sm-9 col-md-9">
                        <div className="vwd-login-left">
                            <div className="vwd-logo">
                                <img src="/images/login-logo.svg" alt="Logo" />
                            </div>
                            <h1 className="vwd-event-date">01-02 March 2023</h1>
                            <h5>University of Miami <span>|</span> Hemophilia Treatment Center, Miami FL 33136</h5>
                        </div>
                    </div>
                    <div className="col-sm-3 col-md-3">
                        <div className="factor-season-right">
                            <div className="factor-season-right-inset">
                                <div className="emory__logo">
                                    <img src="/images/footer-icon-new.png" alt="U Health" />
                                </div>
                                <h6>Chair : Fernando F.<br />Corrales-Medina</h6>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            <Row>
            <div className="consent-form">
                <div className="consent-form-inner">
                    <form  onSubmit={(e) => handleRegister(e)} >
                        <div className="row" id="form_upper">
                            <div className="col-sm-12 col-md-12 center-sided">
                                <h3>Register Today</h3>
                            </div>
                        </div>
                        <div className="row">
                           <div className="col-sm-12 col-md-12 center-sided">
                                <h6>Please agree &amp; submit the below to attend the VWD Academy.<br/> 
                                    Giving us consent to contact will allow us to send you updates, important and curated content from time to time.
                                </h6>
                            </div> 
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-6 consent-form-list">
                                <label>Your name</label>
                                <input type="text" className="form-control" value={username} onChange={(e) => userChanged(e)} id="usr" placeholder="Type Your Name"/>
                                              {validator.message("username", username, "required")}
                            </div>
                            <div className="col-sm-6 col-md-6 consent-form-list">
                                <label>Your email</label>
                                <input type="text" className="form-control" id="email" value={email} onChange={(e) => emailChanged(e)}  placeholder="Type Your Email" name="email"/>
                                {validator.message("email", email, "required|email")}
                            </div>
                            
                            <div className="col-sm-12 col-md-12 consent-form-list">
                                <div className="form-check1">
                                 <div className="consent-form-option">
                                        <div className="option-list">
										   <input type="checkbox" className="form-check-input consent-radio-box" id="option1"  onChange={(e)=>consentchecked(e)}  name="consent_checkbox"  value="My activity being tracked on this webinar page*" />  <label for="option1">My activity being tracked on this VWD Academy page<span>*</span></label>
										</div>
                                        <div className="option-list">
										   <input type="checkbox" className="form-check-input consent-radio-box" id="option2"  onChange={(e)=>consentchecked(e)}  name="consent_checkbox" value="Being contacted by the VWD Academy Staff" />  <label for="option2">Being contacted by the VWD Academy Staff</label>
										</div>
                                        <div className="option-list">
										   <input type="checkbox" className="form-check-input consent-radio-box" id="option3"  onChange={(e)=>consentchecked(e)}  name="consent_checkbox" value="Receive future materials from theVWD Academy Staff" />  <label for="option3">Receive future materials from theVWD Academy Staff</label>
										</div>
                                       
                                    </div>
                                    {validator.message("consent", consent, "required")}  
                                    <div className="indicated-required">
                                        <span>*</span>Required field
                                    </div>
                             
                                
                                </div>
                            </div>

                            <Button type="submit" className="btn btn-primary">Agree &amp; submit</Button>
                        </div>
                    </form> 
                </div>
            </div>
        </Row>
        <div className="footer">
            <Row>
                <Col className="left-sec">
                    <div className="footer-content">
                      <div className="footer_logo">
                        <Link to={"/MU/2023/about"}><img src="/images/footer-logo.svg" alt="Logo" /></Link>
                      </div>
                      <div className="footer-content-link">
                        <ul>
                            <li>
                                <a href="https://www.youtube.com/channel/UCYgtXBu91QV8lGB1P6vIbCA" target="_blank">
                                <img src="/images/fb-icon.svg" alt="Facebook" /></a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/octapharma/" target="_blank">
                                <img src="/images/linkedin-icon.svg" alt="LinkedIn" /></a>
                            </li>
                        </ul>
                         <p>Funded and facilitated by Octapharma AG</p>
                      </div>
                      <div className="footer-privacy-link">
                        <ul>    
                            <li><a href="mailto:vwdacademy@docintel.app">Contact Us</a></li>|
                            <li><a href={"/MU/2022/legal"} >Legal Statement</a></li>|
                            <li><a href ={"/MU/2022/privacy"}>Data Privacy Statement</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="copy_right">
                        Last Updated: March 2023
                    </div>
                </Col>
                <Col className="right-sec">
                    <a href="https://med.miami.edu/departments/pediatrics/divisions/hematology-oncology/hemophilia-treatment-center" target="_blank">
                        <img src="/images/footer-icon-new.png" alt="Footer Image" />
                    </a>
                </Col>                 
            </Row>
        </div>                            
        </Container>
        <Modal show={noemail} onHide={handlenoemail} className="login_modal forgot" centered>
            <Modal.Header closeButton>
            <Modal.Title className='orange_ad'>Thank you for registering.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
         
            
            </Modal.Body>
            
        </Modal>
        
    </div>
    
                
    </>
)
}
export default Register;