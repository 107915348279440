import React ,{useEffect ,useLayoutEffect, useRef}from 'react'
import { Container, Row } from 'react-bootstrap'

const Legal = () => {
    const ref = useRef(null);

    useEffect(() => {
     window.scrollTo(0,0)          
}, []);
   
  return (
    <>
    <div className="privacy-statement" ref={ref}>
        <Container>
            <Row>
                <div className="privacy-statement-view">
                    <h2>Legal STATEMENT</h2>
                    <h3>Terms of use</h3>
                    <div className="privacy-statement-box">
                        <h6>This website is created and controlled by Octapharma AG, Seidenstrasse 2, 8853 Lachen SZ, Switzerland.</h6>
                         <h6>By using this website you agree to abide by the terms and conditions set out below and all applicable laws. If you do not agree to abide by these terms and conditions, do not use this website.</h6>
                    </div>
                    
                    <h3>Medical information</h3>
                    <div className="privacy-statement-box">
                        <h6>Medical information on this website is provided by Octapharma AG and is intended for general information purposes only. The information is furthermore not intended to provide complete medical information. You should always obtain complete medical information about your prescription medicines or medical devices (including their beneficial medical uses and possible adverse effects) by discussing the appropriate use of any medicine/medical devices directly with your prescribing physician or, where appropriate, other medical advisor. The medical information provided does not constitute and does not intend to constitute any inducement to recommend, purchase, prescribe, sell or administer a particular product of the Octapharma group of companies.</h6>
                    </div>
                    
                    <h3>Copyright protection</h3>
                    <div className="privacy-statement-box">
                        <h6>Copyright in all material contained on this website is owned by Octapharma AG, unless otherwise indicated.</h6>
                        <h6>
                            Octapharma AG reserves all rights in the content and design of this website. Unless permitted in writing by Octapharma AG, you may not reproduce, use or copy any content on this website including names, logos, designs, product information and any other material/information used in this website (hereinafter together referred to as the “Information”). You may not use commercially any Information, and you may not cause any of the Information to be framed or embedded into another website without prior written permission from Octapharma AG.
                        </h6>
                        <h6>All Information referred to in this website is the property of its respective owner and should not be used in whole or in part in any jurisdiction without the prior written consent of its owner. Nor should any graphic that is substantially similar to any trademark, logo or name referred to on this website be so used.</h6>
                    </div>
                    
                    <h3>No warranties or representation</h3>
                    <div className="privacy-statement-box">
                        <h6>The Information are provided “as is” and without warranties of any kind either express or implied.</h6>
                        <h6>To the fullest extent permissible by law, Octapharma AG expressly disclaims all representations and warranties, express or implied, in connection with or in respect of this website – including, without limitation any representations or warranties of any kind in relation to:</h6>
                        <ul>
                            <li>The accuracy or completeness of any of the Information contained in this website;</li>
                            <li>The accuracy or completeness of any Information contained in any website hyperlinked to this website.</li>
                            <li>The Information is provided on the basis that all persons accessing the website undertake the responsibility for assessing the accuracy of its content and that they rely on it entirely at their own risk. Octapharma AG (including its directors, affiliates, officers, employees, agents, contractors, successors and assigns) does not bear any liability for loss or damages incurred by any person as a result of reliance placed upon the Information or any other information incorporated by reference.
                            </li>
                        </ul>
                    </div>
                    <h3>Applicable code of conduct</h3>
                    <div className="privacy-statement-box">
                        <h6>Octapharma AG strives to adhere to the provisions of the IFPMA code of Practice. The latest version of said code can be found under the following link: <a target="_blank" href="https://www.ifpma.org/resource-centre/ifpma-code-of-practice/">https://www.ifpma.org/resource-centre/ifpma-code-of-practice/</a></h6>
                    </div>
                    <h3>Limitation of liabilities</h3>
                    <div className="privacy-statement-box">
                        <h6>In no event shall Octapharma AG (including its directors, affiliates, officers, employees, agents, contractors, successors and assigns) be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss of use, data, profit earnings, or lost opportunities, arising out of or in any way connected with the use or performance of this website.</h6>
                    </div>
                    <h3>Third party website</h3>
                    <div className="privacy-statement-box">
                        <h6>As a convenience to you, Octapharma AG may provide, on this website, links to websites operated by other entities. If you use these websites, you will leave this website. If you decide to visit any linked website, you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements.</h6>
                        <h6>Octapharma AG (including its directors, affiliates, officers, employees, agents, contractors, successors and assigns) makes no warranty or representation regarding, and does not endorse, any linked websites or the information appearing thereon or any of the products or services described thereon.</h6>
                        <h6>Links do not imply that Octapharma AG, is affiliated or associated with, or is legally authorized to use any trademark, trade name, logo or copyright symbol displayed in or accessible through the links, or that any linked website is authorized to use any trademark, trade name, logo or copyright symbol of Octapharma AG.</h6>
                        <h6>All links to this website must be approved by Octapharma AG. Octapharma AG reserves the right to revoke this consent to link at any time in its sole discretion.</h6>
                    </div>
                      <h3>Amendments to the website</h3>
                    <div className="privacy-statement-box">
                        <h6>Octapharma AG reserves the right to make changes, modifications, additions, deletions and corrections to this website at any time and without notice. Octapharma AG may also withdraw access to this website at any time without notice.</h6>
                    </div>
                    <h3>Virus protection</h3>
                    <div className="privacy-statement-box">
                        <h6>Octapharma AG does not warrant or represent that this website or emails received from Octapharma AG pursuant to the mailing list facility will be uninterrupted or virus free or free of other harmful components. Octapharma AG (including its directors, affiliates, officers, employees, agents, contractors, successors and assigns) does not bear any responsibility for any damage caused to your equipment as a result of accessing this website, downloading any of the content from this website or receiving emails from Octapharma AG pursuant to the mailing list facility.</h6>
                    </div>
                    <h3>Applicable law</h3>
                    <div className="privacy-statement-box">
                        <h6>These terms of use as well as the privacy statement and the resolution of any dispute related to these terms of use and/or the privacy statement shall be construed in accordance with the laws of Switzerland. Exclusive place of jurisdiction shall be Lachen, SZ, Switzerland.</h6>
                    </div>
                </div>
            </Row>
        </Container>
    </div>
                    
    </>
  )
}

export default Legal