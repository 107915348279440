import React ,{useEffect}from 'react'
import { Container, Row } from 'react-bootstrap'

const Privacy = () => {

  return (
    <>
    <div className="privacy-statement">
        <Container>
            <Row>
                <div className="privacy-statement-view">
                    <h2>DATA PRIVACY STATEMENT</h2>
                    <div className="privacy-statement-box">
                        <h6>VWD Academy website is funded and facilitated by Octapharma AG, Seidenstrasse 2, 8853 Lachen SZ, Switzerland (hereinafter “Octapharma” or “we” or “us”), under the auspices of a dedicated committee comprising VWD specialists and experts.</h6>
                         <h6>This dedicated committee, including Octapharma, respects the right of individuals regarding their personal data. This Data Privacy Statement (“statement”) describes the way your personal information is processed when you visit VWD Academy website.</h6>
                         <h6>Octapharma Pharmazeutika Produktionsgesellschaft m.b.H., Oberlaaer Strasse 235, 1100 Vienna, Austria is appointed as our representative in the EU/EEA in accordance with Article 27 GDPR.</h6>
                    </div>
                    
                    <h3>Collection of information</h3>
                    <div className="privacy-statement-box">
                        <h6>In order to properly manage VWD Academy website, information may be logged and visits categorized by items such as domain name, browser type and version, to continuously improve the performance of the website.</h6>
                    </div>
                    
                    <h3>Disclosure and use of personal information</h3>
                    <div className="privacy-statement-box">
                        <h6>Information will not be disclosed to third parties except where the third party is a supplier or contractor who is involved in fulfilling the individual's request for information from vwdtest.com, or a supplier or contractor who is involved in the development and management of VWD Academy website.</h6>
                    </div>
                    
                    <h3>Information about cookies</h3>
                    <div className="privacy-statement-box">
                        <h6>Cookies are pieces of data that a website can transfer to a user’s web browser. When you visit VWD Academy website, if your browser is using the standard settings and it is accepting cookies, you are giving consent for cookies from this site to be stored on your computer. The cookies stored may remain on your computer after you leave VWD Academy website.</h6>
                        <h6>
If you do not wish for this to happen, you can adjust your web browser to refuse cookies or to warn you when cookies are being used. You can also manually delete them in your browser settings. Most elements of VWD Academy website will still function if you disable cookies in your web browser settings.
</h6>
                        <h6>You can find more information about cookies, including how to see what cookies have been set on your device and how to manage and delete them at <a href="www.aboutcookies.org" target="_blank">www.aboutcookies.org</a>.</h6>
                        <h6>At VWD Academy website different types of cookies are used:
</h6>
                    </div>
                    
                    <h5>Performance cookies</h5>
                        <h6>These enable VWD Academy website to function more smoothly and learn what is valued.</h6>
                    <table className='performence-cookies'>
                        <thead>
                            <tr>
                                <th>Cookie Type</th>
                                <th>Name</th>
                                <th>Purpose</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>user information</td>
                                <td>user-info</td>
                                <td>Used to store data of registered or logged in user basic information so it is faster and easier to log in again</td>
                            </tr>
                            <tr>
                                <td>Token technology</td>
                                <td>token</td>
                                <td>For authentication purpose, we save this token so no anonymous user gets access to the account directly.</td>
                            </tr>
                        </tbody>

                    </table>
                    <h3>Your rights</h3>
                    <div className="privacy-statement-box">
                        <h6>If you have any concerns about your personal data when visiting VWD Academy website you can request to be provided with information about data which is stored/processed about you. In addition, you have the right to have any personal data blocked or deleted (unless the applicable laws and regulations oblige that data to be stored), to object to the processing of your data, to have any incorrect data corrected and the right to have your data transferred to a third party. You also have the right to lodge a complaint with a supervisory authority, if applicable.</h6>
                         <h6>Should you have other questions or concerns about these privacy statement or want to have your data deleted from our database, please send an email to: <a href="mailto:dataprivacy@octapharma.com">dataprivacy@octapharma.com</a></h6>
                          <h6>We may update or amend this statement at any time by posting the amended statement to the website.</h6>
                    </div>
                    
                </div>
            </Row>
        </Container>

    </div>
    </>
  )
}

export default Privacy