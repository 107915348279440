import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter,Routes,Route } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/Pages/About';
import Course from './components/Pages/Course';
import Faculty from './components/Pages/Faculty';
import Testimonial from './components/Pages/Testimonial';
import Register from './components/Pages/Register';
import Privacy from './components/Pages/Privacy';
import Legal from './components/Pages/Legal';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
    <Header />
      <main>
        <Routes>
            <Route path="MU/2022/" element={<App />} />
            <Route path="MU/2022/about" element={<About />} />
            <Route path="MU/2022/course" element={<Course />} />
            <Route path="MU/2022/faculty" element={<Faculty />} />
            <Route path="MU/2022/testimonial" element={<Testimonial />} />
            <Route path="MU/2022/register" element={<Register />} />
            <Route path="MU/2022/privacy" element={<Privacy />} />
            <Route path="MU/2022/legal" element={<Legal />} />
        </Routes>
      </main>
    <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
